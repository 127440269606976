<template>
<div class="page-container">
    <div class="page-top">
        <el-form :inline="true" :model="formData">
            <el-form-item label="搜索：">
                <el-input v-model="formData.Title" @keyup.enter.native="handleSearch" clearable placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询</el-button>
                <el-button type="primary" icon="el-icon-plus" @click="handleAddNew">新增</el-button>
            </el-form-item>
        </el-form>
    </div>
    <div class="page-table">
        <el-table
            stripe
            border
            ref="productTable"
            row-key="Id"
            :data="tableData.items"
            :height="tableData.height"
            v-loading="tableData.loading"
            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="Title" label="标题" />
            <el-table-column prop="SortIndex" label="排序" />
            <el-table-column label="操作" width="210" align="center" header-align="center">
                <template slot-scope="scope">
                    <el-button plain size="mini" @click="handleEdit(scope.row)">编辑</el-button>
                    <el-button plain size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            background
            :total="tableData.total"
            :page-size="tableData.pageSize"
            :current-page="tableData.currentPage"
            :page-sizes="[10, 15, 20, 30, 50]"
            layout="prev, pager, next,total, sizes"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
    </div>
    <div class="page-dialog">
        <document-edit
            ref="documentEdit"
            v-if="editData.visible"
            @handleSearch="handleSearch"
        />
    </div>
</div>
</template>
<script>
import { submit } from "@/api/base";
import DocumentEdit from "./components/Edit.vue";
export default {
    name:'ErpDocument',
    components:{
        DocumentEdit
    },
    data() {
        return {
            loading: false,
            formData:{
                Title:'',
            },
            tableData:{
                loading:false,
                items:[],
                total:0,
                pageSize:15,
                currentPage:1,
            },
            editData:{
                visible:false
            }
        }
    },
    mounted() {
        const _this = this;
        _this.$nextTick(async () => {
            await _this.fetchData()
        });
    },
    methods:{
        async fetchData(){
            const _this = this;

            _this.tableData.loading = true;
            const { data } = await submit("/api/document/getList",{
                PageSize:_this.tableData.pageSize,
                CurrentPage:_this.tableData.currentPage,
                Title:_this.formData.Title,
            }).finally(()=>{
                _this.tableData.loading = false;
            });

            _this.tableData.total = data.total;
            _this.tableData.items = data.datas;
        },
        handleSearch(){
            this.fetchData();
        },
        handleAddNew(){
            const _this = this;
            _this.editData.visible = true;
            _this.$nextTick(() => {
                _this.$refs.documentEdit.initDialog('新增');
            });
        },
        handleEdit(row){
            const _this = this;
            _this.editData.visible = true;
            _this.$nextTick(() => {
                _this.$refs.documentEdit.initDialog(`编辑`,row.Id);
            });
        },
        handleDelete(row){
            this.$confirm(`您是否要删除当前选中标题?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                const result = await submit(`/api/document/delete?id=${row.Id}`);
                if(result.success){
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.fetchData();
                }

            });
        },
        handleSizeChange(size){
            this.tableData.pageSize = size;
            this.fetchData();
        },
        handleCurrentChange(page){
            this.tableData.currentPage = page;
            this.fetchData();
        }
    }
}
</script>
<style lang="scss" scoped>
.page-top {
  padding: 6px 14px 0 14px;
}
.page-table{
    text-align: right;
    padding: 0 14px 0 14px;
}
</style>